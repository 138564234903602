import ReactDOM from 'react-dom/client';
import './i18n/i18next';
import { Suspense, lazy } from 'react';
import { ConfigComponent } from '@sobrus-com/sobrus-design-system-v2';
import ReloadPrompt from './components/ReloadPrompt/ReloadPrompt';
import './services/pwaRefetch';
const App = lazy(() => import('./App'));

// if (import.meta.env.VITE_REACT_APP_ENV !== 'development') {
//   Sentry.init({
//     release: '3.0.6',
//     dsn: '',
//     environment: import.meta.env.VITE_REACT_APP_ENV,
//     integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
//     beforeSend(event, hint) {
//       const error = hint?.originalException as AxiosError;
//       if (error) {
//         return null;
//       } else {
//         return event;
//       }
//     },
//     tracesSampleRate: 0.2,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1.0,
//   });
// }

const ContainerApp = () => {
  return (
    <>
      <Suspense fallback={null}>
        <ConfigComponent fonts={{ fontFamily: 'Poppins' }} solution='pharma'>
          <App />
          <ReloadPrompt />
        </ConfigComponent>
        <div
          style={{
            backgroundColor: 'red',
            width: '360px',
            position: 'absolute',
          }}
        ></div>
      </Suspense>
    </>
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<ContainerApp />);
